import '../css/app.css'

import _ from 'lodash'
import 'dynamic-import-polyfill'
import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { InertiaProgress } from '@inertiajs/progress'
import { Quasar, Notify, Dialog, Loading } from 'quasar'
import { createI18n } from 'vue-i18n'

import messages from '@intlify/unplugin-vue-i18n/messages'

window._ = _
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

window.Pusher = Pusher

//
//To-Do
//Put this back
//
window.Echo = new Echo({
	broadcaster: 'pusher',
	key: '4e9bf249c0baf8391cb9',
	cluster: 'us2',
	forceTLS: false
})

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

const i18n = createI18n({
	legacy: false, // you must set `false`, to use Composition API
	locale: 'en',
	fallbackLocale: 'en',
	formatFallbackMessages: true,
	globalInjection: true,
	silentFallbackWarn: true,
	silentTranslationWarn: true,
	messages
})

createInertiaApp({
	title: (title) => `${title} - ${appName}`,

	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),

	// resolve: name => {
	// 	const pages = import.meta.glob('./Pages/**/*.vue')

	// 	const importPage = pages[`./Pages/${name}.vue`]
	// 	if (!importPage) {
	// 		throw new Error(`Unknown page ${name}. Is it located under Pages with a .vue extension?`)
	// 	}
	// 	//// const sasa = import.meta.glob(['./Components/Permissions/Show.vue', './Components/subform.vue'])
	// 	//// const importPage2 = sasa['./Components/Permissions/Show.vue']
	// 	//// return importPage().then(module => module.default).then(importPage2().then(module => module.default))

	// 	return importPage().then(module => module.default)
	// },


	setup ({ el, App, props, plugin }) {
		const myApp = createApp({
			render: () => h(App, props)
		})
			.use(plugin)
			.use(i18n)
			.use(Quasar, {
				plugins: {
					Notify,
					Dialog,
					Loading,
				},
				config: {
					boot: [
						'/src/boot/quasar-lang-pack'
					],
					loading:
						{
							delay: 400,
							message: 'Procesing data... Please Wait...',
						},
					brand: {
					// primary: '#fa7262',
					},
				}
			})
			.mixin({ methods: { route }
		})

		myApp.config.globalProperties.trans = string => _.get(window.i18n, string)

		myApp.config.globalProperties.capitalizeFirstLetter = function (string) {
			string = typeof this.trans(string) == 'undefined' ? string : this.trans(string)
			return string.charAt(0).toUpperCase() + string.slice(1)
		}

		myApp.config.globalProperties.translateIt = function (field, label) {
			if (label) {
				let translated = this.trans('field.' + field)

				if (translated) {
					return translated
				}
			}
			return label
		}

		myApp.config.globalProperties.$route = route


		myApp.mount(el)
		return myApp
	},
})

InertiaProgress.init({ delay: 0, color: '#4B5563', includeCSS: true, howSpinner: true })
